import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _setup$editBaseData;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main"
  }, [_c('h2', {
    staticClass: "page-title"
  }, [_vm._v("掲示板 - " + _vm._s(_setup.pageTitle))]), _setup.isCreate || (_setup$editBaseData = _setup.editBaseData) !== null && _setup$editBaseData !== void 0 && _setup$editBaseData.title ? _c('form', {
    staticClass: "form",
    attrs: {
      "action": ""
    }
  }, [_setup.isCreate ? [_c('p', {
    staticClass: "form__heading"
  }, [_vm._v("掲示板種類")]), _c('fieldset', {
    staticClass: "form__inputs forum-type"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.editData.target,
      expression: "editData.target"
    }],
    staticClass: "radio",
    attrs: {
      "id": "school",
      "value": "school",
      "name": "target",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_setup.editData.target, "school")
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_setup.editData, "target", "school");
      }, _setup.removeSelectSchool]
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "school"
    }
  }, [_vm._v("先生")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.editData.target,
      expression: "editData.target"
    }],
    staticClass: "radio",
    attrs: {
      "id": "guardian",
      "value": "guardian",
      "name": "target",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_setup.editData.target, "guardian")
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_setup.editData, "target", "guardian");
      }, _setup.removeSelectSchool]
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "guardian"
    }
  }, [_vm._v("保護者")])])] : _vm._e(), _c('p', {
    staticClass: "form__heading"
  }, [_vm._v("送信先")]), _c('fieldset', {
    staticClass: "form__inputs recipient"
  }, [_c('button', {
    staticClass: "select-button",
    attrs: {
      "type": "button",
      "aria-haspopup": "true"
    },
    on: {
      "click": function click($event) {
        return _setup.toggleIsShowModal(true);
      }
    }
  }, [_vm._v(" 送信先を選択 ")]), _c('button', {
    staticClass: "cancel-button",
    attrs: {
      "type": "button",
      "disabled": !_setup.editData.school_ids.length
    },
    on: {
      "click": function click($event) {
        return _setup.toggleIsShowCancelConfirmModal(true);
      }
    }
  }, [_vm._v(" 選択解除 ")]), _setup.editData.school_ids.length === 0 && _setup.error.school_ids ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.school_ids) + " ")]) : _vm._e(), _setup.editData.school_ids.length ? _c('ForumSchoolCount', {
    attrs: {
      "select-school-id-list": _setup.editData.school_ids
    }
  }) : _vm._e(), _setup.editData.school_ids.length ? _c('span', [_vm._v("  選択中")]) : _vm._e()], 1), _c('p', {
    staticClass: "form__heading"
  }, [_vm._v("有効期限")]), _c('div', {
    staticClass: "form__inputs period"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.editData.expire_on,
      expression: "editData.expire_on"
    }],
    staticClass: "date",
    attrs: {
      "type": "date",
      "min": _setup.cdate().format('YYYY-MM-DD'),
      "max": _setup.cdate().add(3, 'year').format('YYYY-MM-DD')
    },
    domProps: {
      "value": _setup.editData.expire_on
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.editData, "expire_on", $event.target.value);
      }
    }
  })]), _c('p', {
    staticClass: "form__heading"
  }, [_vm._v("重要")]), _c('div', {
    staticClass: "form__inputs"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.editData.is_important,
      expression: "editData.is_important"
    }],
    staticClass: "checkbox",
    attrs: {
      "id": "important",
      "type": "checkbox",
      "name": "important"
    },
    domProps: {
      "checked": Array.isArray(_setup.editData.is_important) ? _vm._i(_setup.editData.is_important, null) > -1 : _setup.editData.is_important
    },
    on: {
      "change": function change($event) {
        var $$a = _setup.editData.is_important,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_setup.editData, "is_important", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_setup.editData, "is_important", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_setup.editData, "is_important", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "important"
    }
  }, [_vm._v("重要なお知らせ")])]), _setup.editData.target === 'guardian' ? [_c('p', {
    staticClass: "form__heading"
  }, [_vm._v("メール配信")]), _c('div', {
    staticClass: "form__inputs"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.useEmail,
      expression: "useEmail"
    }],
    staticClass: "checkbox",
    attrs: {
      "id": "toGuardian",
      "type": "checkbox",
      "name": "toGuardian"
    },
    domProps: {
      "checked": Array.isArray(_setup.useEmail) ? _vm._i(_setup.useEmail, null) > -1 : _setup.useEmail
    },
    on: {
      "change": function change($event) {
        var $$a = _setup.useEmail,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_setup.useEmail = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_setup.useEmail = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _setup.useEmail = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "toGuardian"
    }
  }, [_vm._v("メールを配信する")])])] : _vm._e(), _c('label', {
    staticClass: "form__heading",
    attrs: {
      "for": "title"
    }
  }, [_vm._v("記事のタイトル")]), _c('div', {
    staticClass: "form__inputs"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.editData.title,
      expression: "editData.title"
    }],
    staticClass: "input",
    attrs: {
      "id": "title",
      "name": "title",
      "type": "text"
    },
    domProps: {
      "value": _setup.editData.title
    },
    on: {
      "change": _setup.validateTitle,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.editData, "title", $event.target.value);
      }
    }
  }), _setup.error.title ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.title) + " ")]) : _vm._e()]), _c('label', {
    staticClass: "form__heading",
    attrs: {
      "for": "content"
    }
  }, [_vm._v("メッセージ本文")]), _c('div', {
    staticClass: "form__inputs"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.editData.message,
      expression: "editData.message"
    }],
    staticClass: "textarea",
    attrs: {
      "id": "content",
      "name": "content"
    },
    domProps: {
      "value": _setup.editData.message
    },
    on: {
      "change": _setup.validateMessage,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.editData, "message", $event.target.value);
      }
    }
  }), _setup.error.message ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.message) + " ")]) : _vm._e()]), _c('p', {
    staticClass: "form__heading"
  }, [_vm._v("添付ファイル")]), _c('fieldset', {
    staticClass: "form__inputs attached"
  }, [_vm._l(_setup.attachmentList, function (_, i) {
    return _c('ForumEditAttachment', {
      key: i,
      on: {
        "toggleIsDelete": _setup.toggleIsDelete
      },
      model: {
        value: _setup.attachmentList[i],
        callback: function callback($$v) {
          _vm.$set(_setup.attachmentList, i, $$v);
        },
        expression: "attachmentList[i]"
      }
    });
  }), _c('p', {
    staticClass: "note"
  }, [_vm._v(" 1件のPDF・画像（pdf,jpg,jpeg,png,gif）ファイルを添付できます（5MBまで） ")])], 2), _c('div', {
    staticClass: "form__bottom"
  }, [_c('button', {
    staticClass: "cancel",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.router.go(-1);
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('input', {
    staticClass: "submit",
    attrs: {
      "type": "button",
      "value": "記事投稿"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _setup.handleMailPreview.apply(null, arguments);
      }
    }
  })]), _setup.isShowConfirmModal ? _c('MessageModal', {
    attrs: {
      "ok-button": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("メールの内容を確認してください"), _c('br'), _vm._v("（※添付ファイルはこの画面では確認できません。）")];
      },
      proxy: true
    }, {
      key: "message",
      fn: function fn() {
        return [_c('section', {
          staticClass: "mail-preview"
        }, [_c('h2', {
          staticClass: "mail-preview__title"
        }, [_vm._v("記事タイトル")]), _c('p', {
          staticClass: "mail-preview__from-to"
        }, [_vm._v(" " + _vm._s(_setup.editData.title) + " ")]), _c('div', {
          staticClass: "mail-preview__body"
        }, [_vm._v(_vm._s(_setup.editData.message))]), _setup.attachmentList[0] ? _c('div', {
          staticClass: "attachment"
        }, [_c('p', {
          staticClass: "attachment__heading"
        }, [_vm._v("添付ファイル")]), _c('button', {
          staticClass: "attachment__button",
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, [_vm._v(" プレビュー ")]), _c('p', {
          staticClass: "attachment__notes"
        }, [_vm._v(" (30分以内にプレビューしてください) ")])]) : _vm._e()]), _c('div', {
          staticClass: "destination-confirm"
        }, [_c('button', {
          staticClass: "destination-confirm__button",
          attrs: {
            "id": "destination"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _setup.toggleIsShowModal(true);
            }
          }
        }, [_vm._v(" 送信先を確認 ")]), _c('label', {
          staticClass: "destination-confirm__label",
          attrs: {
            "for": "destination"
          }
        }, [_setup.editData.school_ids.length ? _c('ForumSchoolCount', {
          attrs: {
            "select-school-id-list": _setup.editData.school_ids
          }
        }) : _vm._e(), _vm._v(" 選択中 ")], 1)]), _c('div', {
          staticClass: "buttons"
        }, [_c('button', {
          staticClass: "buttons__cancel",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _setup.toggleIsShowConfirmModal(false);
            }
          }
        }, [_vm._v(" キャンセル ")]), _c('button', {
          staticClass: "buttons__submit",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _setup.createEditForum.apply(null, arguments);
            }
          }
        }, [_vm._v(" メールを送信する ")])])];
      },
      proxy: true
    }], null, false, 2656333931)
  }) : _vm._e()], 2) : _vm._e(), _setup.isShowModal ? _c('ForumSelect', {
    attrs: {
      "target": _setup.editData.target,
      "select-school-id-list": _setup.editData.school_ids,
      "select-grade-id-list": _setup.editOnlyGuardianData.grade_ids
    },
    on: {
      "updeteSchoolIds": _setup.updeteSchoolIds,
      "updeteGradeIds": _setup.updeteGradeIds,
      "toggleIsShowModal": _setup.toggleIsShowModal
    }
  }) : _vm._e(), _setup.isShowCancelConfirmModal ? _c('ConfirmModal', {
    on: {
      "clickOK": _setup.removeSelectSchool,
      "clickCancel": function clickCancel($event) {
        return _setup.toggleIsShowCancelConfirmModal(false);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("選択解除")];
      },
      proxy: true
    }, {
      key: "message",
      fn: function fn() {
        return [_c('p', [_vm._v("送信先をクリアします。")]), _c('p', [_vm._v("よろしいですか？")])];
      },
      proxy: true
    }], null, false, 2660566132)
  }) : _vm._e(), _setup.isShowMessageModal ? _c('MessageModal', {
    on: {
      "close": _setup.closeMessageModal
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(_vm._s(_setup.modalMessage.title))];
      },
      proxy: true
    }, {
      key: "message",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_setup.modalMessage.message) + " ")];
      },
      proxy: true
    }], null, false, 4262614249)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };